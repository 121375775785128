<template>
  <v-card class="course-tile pt-4" width="100%" height="100%">
    <CourseIcon
      :icon="course.icon"
      :contain="true"
      height="120px"
    />

    <v-card-text class="pt-5 justify-center course-title">
      {{ course.name }}
    </v-card-text>
  </v-card>
</template>

<script>
  import CourseIcon from '@/components/ui/course/Icon'

  export default {
    name: 'Course',
    components:{
      CourseIcon,
    },
    props:{
      course: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>

  .course-tile{
    min-height:250px;
    border:1px solid #ddd;
    border-radius:3px;
    -webkit-box-shadow: 10px 10px 4px -9px rgba(0,0,0,0.29);
    -moz-box-shadow: 10px 10px 4px -9px rgba(0,0,0,0.29);
    box-shadow: 10px 10px 4px -9px rgba(0,0,0,0.29);
  }

  @media (max-width: 768px){
    .course-tile{
      min-height: 120px;
    }
  }

  .course-title{
    font-size:1rem;
    text-align: center;
  }

  .course-tile:hover{
    background: rgb(247,247,247);
    cursor:pointer;
  }
</style>
