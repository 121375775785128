<template>
  <div>
    <div v-if="true === loading" class="course-list">
      <v-row>
        <v-col
          cols="12"
          md="3"
          v-for="i in 8"
          :key="i"
          class="course-link"
        >
          <v-skeleton-loader
            ref="skeleton"
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div v-if="false === loading && 0 === courses.length">
      <v-container fluid grid-list-md>
        <v-row>
          <v-col>
            <v-list-item color="grey">
              <v-list-item-avatar>
                <v-icon color="grey">search</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <h2>Todavía no tienes cursos asignados</h2>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-if="false === loading && courses.length > 0" class="course-list">
      <v-container fluid grid-list-md>
        <v-row class="pa-0 ma-0">
          <v-col
            cols="12"
            md="3"
            v-for="course in courses"
            :key="course.id"
          >
            <router-link
              :to="{
                name: 'course',
                params: {
                  course: course.course_code
                }
              }"
            >
            <Course
              :course="course"
              :average="course.stats.average"
            />
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  import Course from './Course'

  export default {
    name: 'CourseList',
    components:{
      Course
    },
    props: {
      courses:{
        type: Array
      },
      loading:{
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style scoped>

</style>
