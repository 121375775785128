<template>
  <v-row no-gutters>
    <v-col cols="12">
      <CourseList :courses="courses" :loading="fetching" />
    </v-col>
  </v-row>
</template>

<script>

  import { mapGetters } from 'vuex'

  import CourseList from '@/components/course/list'

  export default{
    name: 'CurrentCoursesModule',
    components:{
      CourseList
    },
    data(){
      return{
        firstTime: true,
      }
    },
    computed: {
      ...mapGetters({
        courses: 'services/api/course/unified/courses',
        fetching: 'services/api/course/unified/fetching',
        translations: 'services/api/translation/getTranslations'
      })
    },
    created(){
      let me = this;

      if(me.courses.length > 0){
        me.firstTime = false;
        return;
      }

      me.firstTime = true;

      me.$store.dispatch('services/api/course/unified/fetch',{
        delay:2000
      });
    }
  }
</script>

<style scoped>
</style>
