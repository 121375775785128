<template>
  <v-content v-if="authenticated" id="dashboard-view">

    <Toolbar/>

    <v-container class="pa-2">
          <Title :text="translations.dashboard.active_courses.title" />
          <v-divider class="mb-2" />

          <UnifiedCourses />
    </v-container>
  </v-content>
</template>

<script>
  import TranslationsMixin      from '@/mixins/Translation'

  import Toolbar                from '@/components/toolbar/index'
  import Title                  from '@/components/ui/text/Title'
  import UnifiedCourses         from '@/modules/course/unified'

  import { mapGetters } from 'vuex'

  export default{
    name: 'DashboardView',
    mixins:[
      TranslationsMixin
    ],
    components:{
      Toolbar,
      Title,
      UnifiedCourses
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
        focused: 'services/browser/window/focused',
      })
    }
  }
</script>

<style scoped>
</style>
